import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { LinkList } from "./LinkList";
import './HomeLinkTree.css';
import styled from "styled-components/macro";
import mainLogo from './../../kingGardenistLogo.png';
import { ProductsContext } from '../Products/ProductContext';
// import { Product } from '../Products/Product';
import Product from '../Products/Product';

import { HeaderSection } from '../Common/Header';

const HeaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    flex-direction: column;
`;

const ButtonsWrapper = styled.div`
    width: 85%;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    margin-bottom: 50px;
`;

const HeaderButton = styled.button`
    width: 100%;
    border-style: solid;
    border-width: 0px;
    border-radius: 50px;
    box-shadow: 5px 5px 10px -3px #000;
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: #4c956c;
    position: relative;
    z-index: 1;
    padding: 0.5rem;
    &:hover {
        background-color: var(--main-color);
    }
`;

const HomeLinkTree = () => {
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 960px)").matches);
    const { products, loading } = useContext(ProductsContext);
    const navigate = useNavigate();

    // Use useEffect to handle the media query listener
    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 960px)");
        const handler = e => setMatches(e.matches);

        mediaQuery.addEventListener("change", handler);

        // Cleanup the event listener on component unmount
        return () => mediaQuery.removeEventListener("change", handler);
    }, []);

    // if (loading) return <div>Loading...</div>;

    // List of substrings to check for in s3Object
    const substringsToMatch = ["1658928390", "1722638998", "1561889560", "1724244273", "1706370444", "1772831038"]; // Add more as needed

    // Filter products where s3Object includes any of the substrings
    const filteredProducts = products.filter(product =>
        substringsToMatch.some(substring => product.src.includes(substring))
    );

    const handleProductClick = (product) => {
        navigate(`/products`); // Assuming each product has a unique ID
    };

    return (
        <HeaderSection>
            <HeaderWrapper>
                
                    <img src={mainLogo} className="Logo" alt="Main Logo" />
                    <div className="Slogan">
                        <h1>Stickers made for</h1>
                        <h1>nature lovers!</h1>
                    </div>
                    <div className="filtered-products" onClick={handleProductClick}>
                        {filteredProducts.map((product, index) => (
                            <div className="front-page-product">
                                <Product key={index} product={product} onAddToCart={null}/>
                            </div>
                        ))}
                    </div>
                    <ButtonsWrapper>
                    {LinkList.map((item, index) => (
                        <div className="MediaLink" key={item.title}>
                            <a href={item.url}>
                                <HeaderButton>
                                    <img className="LinkBtnImg" src={item.imgSrc} alt={item.title} />
                                    <div className="LinkBtnTitle">
                                        {item.title}
                                    </div>
                                </HeaderButton>
                            </a>
                        </div>
                    ))}
                </ButtonsWrapper>
            </HeaderWrapper>
        </HeaderSection>
    );
};

export default HomeLinkTree;
