import React, { createContext, useState, useEffect } from 'react';

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchProducts() {
            try {
                const response = await fetch('https://kgbucket5856a-dev.s3.us-east-2.amazonaws.com/resources.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch products');
                }
                const data = await response.json();

                const formattedProducts = data.map(item => ({
                    title: item.title.replace(/&#39;/g, "'"),
                    src: `https://kgbucket5856a-dev.s3.us-east-2.amazonaws.com/${item.s3Object}`,
                    price: 5.20,
                }));

                setProducts(formattedProducts.reverse());
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchProducts();
    }, []);

    return (
        <ProductsContext.Provider value={{ products, loading }}>
            {children}
        </ProductsContext.Provider>
    );
};
