import { Amplify } from 'aws-amplify';
import { AuthProvider } from "react-oidc-context";
import amplifyconfig from './amplifyconfiguration.json';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { ProductsProvider } from './components/Products/ProductContext';

const cognitoAuthConfig = {
  authority: process.env.REACT_APP_COGNITO_AUTHORITY,
  client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI,
  response_type: "code",
  scope: "openid email",
};

Amplify.configure(amplifyconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider {...cognitoAuthConfig}>
      <ProductsProvider>
        <App />
      </ProductsProvider>
    </AuthProvider>
    
  </React.StrictMode>
);
