export const Monstera = require('./../../Images/monstera.png');
export const CattleyaAurear = require('./../../Images/cattleyaAurear.png');
export const Sunflower = require('./../../Images/sunflower.png');
export const AutumnLeaves = require('./../../Images/autumnLeaves.png');
export const BlackCat = require('./../../Images/BlackCat.avif');
export const HighlandCow = require('./../../Images/HighlandCow.avif');
export const WindowCat = require('./../../Images/WindowCat.avif');
export const FloralBook = require('./../../Images/FloralBook.avif');
export const Pigeon = require('./../../Images/Pigeon.avif');
export const Llama = require('./../../Images/Llama.avif');
export const Bee = require('./../../Images/Bee.avif');

export const MenuProductImgs = [
    {
        title: 'Black Cat',
        src: BlackCat,
        price: 5.20,
        cName: 'product-img',
        path: "#"
    },
    {
        title: 'Highland Cow',
        src: HighlandCow,
        price: 5.20,
        cName: 'product-img',
        path: "#"
    },
    {
        title: 'Black Cat Autumn Window Sticker, Fall Stickers, Waterproof Vinyl, Witchy Sticker, Kindle Stickers, Magical, Halloween, Cats',
        src: WindowCat,
        price: 5.20,
        cName: 'product-img',
        path: "#"
    },
    {
        title: 'Floral Book',
        src: FloralBook,
        price: 5.20,
        cName: 'product-img',
        path: "#"
    },
    {
        title: 'Pigeon',
        src: Pigeon,
        price: 5.20,
        cName: 'product-img',
        path: "#"
    },
    {
        title: 'Cute Llama Sticker, Laptop Sticker, Animals lover, waterproof stickers, planner stickers, Llama Stickers, Kindle Sticker, Alpaca',
        src: Llama,
        price: 5.20,
        cName: 'product-img',
        path: "#"
    },
    {
        title: 'Kawaii Bee with Flower Sticker, Journal Sticker, Bug Decal, Waterproof vinyl, Bee Stickers, Bumblebee Sticker, Cute Chibi Bee',
        src: Bee,
        price: 5.20,
        cName: 'product-img',
        path: "#"
    }
]