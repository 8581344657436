import React, { useEffect, useState, useContext } from 'react';
import { getUrl, list } from 'aws-amplify/storage';
import { HeaderSection } from '../Common/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import {TButton} from '../Common/Header';
import Product from './Product';
import './ProductPage.css';
import { ProductsContext } from './ProductContext';

const ProductPage = () => {
    // const [imageUrls, setImageUrls] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
    const navigate = useNavigate();
    const location = useLocation();

    const { products, loading } = useContext(ProductsContext);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const page = parseInt(query.get('page')) || 1;
        setCurrentPage(page);

    }, [location.search]);

    if (loading) return <div>Loading...</div>;

    const totalPages = Math.ceil(products.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentImages = products.slice(startIndex, startIndex + itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            navigate(`?page=${currentPage + 1}`);
            window.scrollTo(0, 0);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            navigate(`?page=${currentPage - 1}`);
            window.scrollTo(0, 0);
        }
    };

    const handleJumpToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            navigate(`?page=${page}`);
            window.scrollTo(0, 0);
        }
    };

    return (
        <HeaderSection>
            <div className="product-page">
                {/* <h2>Sticker Gallery</h2> */}

                <h2 className='product-message'>All products can be purchased from <a href='https://www.etsy.com/shop/KindGardenist' style={{'textDecoration':"underline"}}>Etsy Store</a></h2>
                <div className="product-list">
                    {currentImages.map((product, index) => (
                    <Product key={index} product={product} onAddToCart={null}/>
                    ))}
                </div>

                {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {currentImages.map((url, index) => (
                        <img key={index} src={url} alt={`Image ${startIndex + index}`} style={{ width: '150px', margin: '10px' }} />
                    ))}
                </div> */}

                <div className="pagination-controls" style={{ textAlign: 'center' }}>
                    <TButton className='control-btn' onClick={handlePreviousPage} disabled={currentPage === 1} >&larr;</TButton>
                    <span className='PageControl'> Page {currentPage} of {totalPages} </span>
                    <TButton className='control-btn' onClick={handleNextPage} disabled={currentPage === totalPages} >&rarr;</TButton>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <label htmlFor="jumpPage" className='JumpControl'>Jump to page: </label>
                    <input
                        type="number"
                        id="jumpPage"
                        min="1"
                        max={totalPages}
                        value={currentPage}
                        onChange={(e) => handleJumpToPage(Number(e.target.value))}
                    />
                </div>
            </div>
        </HeaderSection>
    );
};

export default ProductPage;
