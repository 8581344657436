
import React from 'react';
import Navbar from './components/NavBar/Navbar';
import {MenuItems} from "./components/NavBar/MenuItems"
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import ThankYouPage from './components/Thank/Thank';
import ThankYouContactPage from './components/Thank/ThankContact';
import ErrorContactPage from './components/Contact/Error';
import SurveyPage from './components/Survey/Survey';
import ProductPage from './components/Products/ProductPage'
import Footer from './components/Footer/Footer';
import ProtectedRoute from './components/Admin/ProtectedRoute';
import AdminPage from './components/Admin/AdminPage';

function App() {
  return (
    <div className='App'>
      <Router>
        <Navbar/>
        <Routes>
          {MenuItems.map((item,index)=> {
              return (
                  <Route key={item.path} path={item.path} element={item.element}/>
              )
          })}
          <Route key="/thanks" path="/thanks" element={<ThankYouPage/>}/>
          <Route key="/contact-thanks" path="/contact-thanks" element={<ThankYouContactPage/>}/>
          <Route key="/contact-error" path="/contact-error" element={<ErrorContactPage/>}/>
          <Route key="/survey" path="/survey" element={<SurveyPage/>}/>
          <Route key="/products" path="/products" element={<ProductPage/>}/>
          <Route key="/logout" path="/logout" element={<ThankYouPage/>}/>

          {/* Protected route */}
          <Route 
              key="/admin"
              path="/admin" 
              element={
                  <ProtectedRoute>
                      <AdminPage />
                  </ProtectedRoute>
              } 
          />
          
        </Routes>        
      </Router>
      <Footer/>
    </div>
  );
}

export default App;