import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const ProtectedRoute = ({ children }) => {
    const auth = useAuth();

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    // If not authenticated, redirect to the login page
    if (!auth.isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    // If authenticated, render the requested component
    return children;
};

export default ProtectedRoute;
