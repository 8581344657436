import React, { useState, useEffect } from 'react';
import {HeaderSection} from '../Common/Header';

const AdminPage = () => {
    const [listings, setListings] = useState(null);
    const [error, setError] = useState(null);

    // Fetch listings when called
    const fetchListings = async () => {
        try {
            const response = await fetch('http://localhost:3003/listings');
            if (!response.ok) throw new Error('Failed to fetch data');
            const data = await response.json();
            setListings(data);  // Store data in state
        } catch (error) {
            console.error('Error fetching listings:', error);
            setError('Error fetching data');
        }
    };

    return (
        <HeaderSection>
            <h2>Etsy API Ping</h2>
            <a href="http://localhost:3003/auth/listings">
                Authenticate with Etsy - Listings
            </a>
            <h2>Get listings</h2>
            <button onClick={fetchListings}>Get Listings</button>

            {/* Conditional rendering for error or listings */}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {listings ? (
                <pre>{JSON.stringify(listings, null, 2)}</pre>
            ) : (
                <p>No listings fetched yet.</p>
            )}
        </HeaderSection>
    );
};

export default AdminPage;
