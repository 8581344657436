import React, { useState } from "react";
import { MenuItems } from "./MenuItems";
import mainLogo from './../../kingGardenistLogo.png';
import './Navbar.css';
import { useAuth } from 'react-oidc-context';

// Access environment variables
const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
const redirectUri = process.env.REACT_APP_COGNITO_REDIRECT_URI;
const logoutUri = process.env.REACT_APP_COGNITO_LOGOUT_URI;
const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;

const Navbar = () => {
    const [clicked, setClicked] = useState(false);
    const auth = useAuth();

    const handleClick = () => {
        setClicked(!clicked);
    };

    const signOutRedirect = async () => {
        try {
            await auth.removeUser();  // Clear local session
            window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
        } catch (error) {
            console.error("Error during sign out:", error);
        }
    };

    if (auth.isLoading) {
        return <div>Loading...</div>;  // Prevent rendering while loading
    }

    return (
        <nav className="MainNavBar">
            {/* Logo Section */}
            <a className="div-logo" href="/">
                <img 
                    src={mainLogo} 
                    className={clicked ? 'navbar-logo active' : 'navbar-logo'} 
                    alt="Logo" 
                />
            </a>

            {/* Mobile Menu Icon */}
            <div className="menu-icon" onClick={handleClick}>
                <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>

            {/* Navigation Links */}
            <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                {MenuItems.map((item, index) => (
                    <li key={index}>
                        <a className={item.cName} href={item.path}>{item.title}</a>
                    </li>
                ))}

                {/* Admin Link (Only Visible When Authenticated) */}
                {auth.isAuthenticated && (
                    <li>
                        <a className="nav-links" href="/admin">Admin</a>
                    </li>
                )}

                {/* Sign-in/Sign-out Button */}
                <li>
                    {auth.isAuthenticated ? (
                        <button 
                            className="nav-links button-link" 
                            onClick={signOutRedirect}
                        >
                            Sign Out
                        </button>
                    ) : (
                        <button 
                            className="nav-links button-link" 
                            onClick={() => auth.signinRedirect()}
                        >
                            Sign In
                        </button>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
