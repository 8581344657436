import ContactPage from '../Contact/Contact';
import HomeLinkTree from '../HomeLinkTree/HomeLinkTree';
import ProductPage from '../Products/ProductPage';

export const MenuItems = [
    {
        title: 'Home',
        path: '/',
        element: <HomeLinkTree/>,
        cName: 'nav-links'
    },
    {
        title: 'Stickers',
        path: '/products',
        element: <ProductPage/>,
        cName: 'nav-links'
    },
    {
        title: 'Contact Us',
        path: '/contact',
        element: <ContactPage/>,
        cName: 'nav-links'
    }
]
