import React from 'react';
import PropTypes from 'prop-types';
import './Product.css';

const Product = ({ product, onAddToCart }) => {
  return (
    <div className="product-card" title={product.title}>
      <img src={product.src} alt={product.title} className="product-image" />
      <div className="product-info">
        <div className="product-name">{product.title}</div>
        <span className="product-price">
            <span className="product-price-sign">$</span>
            <span className="product-price-whole">{Math.floor(product.price)}</span>
            <span className="product-price-fraction">.{(product.price % 1).toFixed(2).substring(2)}</span>
        </span>

      </div>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
  }).isRequired,
  // onAddToCart: PropTypes.func.isRequired,
};

export default Product;